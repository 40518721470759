import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import {
  RiCalendarTodoLine,
  RiMessage2Line,
  RiHome5Line,
  RiRouteFill
} from "@remixicon/react";
import ShowingActions from "~brokerage/components/shared/ShowingActions";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import { platformUrl } from "~brokerage/constants/externalLinks";
import css from "./DetailLinks.sass";

const DetailLinks = ({
  timestamp,
  uniqueId,
  mlsId,
  platformName,
  showingStatus,
  openStatusModal,
  isShowingTeam,
  isListingTeam,
  futureShowing,
  history,
  route: {
    route_id: routeId,
    route_status: routeStatus,
    route_start_at: routeStartAt
  },
  listingKey,
  closeModal,
  isRoutesEnabled,
  openDateTimeModal,
  setIsListingAgentPreapproving
}) => {
  const LinkButton = ({
    link,
    text,
    Icon,
    disabled,
    external = false,
    reload = false,
    close = false
  }) => {
    return (
      <div
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            if (external) {
              window.open(link);
            } else {
              history.push(link);
              reload && location.reload();
            }
            close && closeModal();
          }
        }}
        className={composeClassName(css, "detailLink", disabled && "disabled")}
      >
        <Icon
          color={disabled ? "#bfbfbf" : "#344c5ee3"}
          size="16px"
          className={css.Icon}
        />
        {text}
      </div>
    );
  };
  const calendarLink = `/calendar/day?timestamp=${
    moment(timestamp).toISOString().slice(0, -5) + "Z"
  }`;
  const messageLink = `/messages/showing/${uniqueId}`;
  const externalMLSLink = platformUrl(mlsId, platformName, listingKey);
  const routeLink = `/my_showings/${routeId}/schedule`;

  const isInRoute = isShowingTeam && routeId;

  const routeCancelledOrExpired =
    (isInRoute && routeStatus === "cancelled") ||
    (isInRoute && moment(routeStartAt).isBefore(moment().startOf("day")));

  const disableViewInRoute = isInRoute && routeCancelledOrExpired;

  return (
    <>
      <LinkButton
        link={calendarLink}
        text="View in Calendar"
        Icon={RiCalendarTodoLine}
        close={true}
      />
      <LinkButton
        link={messageLink}
        text="View in Messages"
        Icon={RiMessage2Line}
      />
      <LinkButton
        link={externalMLSLink}
        text="View Listing"
        Icon={RiHome5Line}
        external={true}
      />
      {isRoutesEnabled && isShowingTeam && routeId && (
        <LinkButton
          disabled={disableViewInRoute}
          link={routeLink}
          text="View in Routes"
          Icon={RiRouteFill}
        />
      )}
      <ShowingActions
        showingStatus={showingStatus}
        openStatusModal={openStatusModal}
        isShowingTeam={isShowingTeam}
        isListingTeam={isListingTeam}
        futureShowing={futureShowing}
        openDateTimeModal={openDateTimeModal}
        setIsListingAgentPreapproving={setIsListingAgentPreapproving}
      />
    </>
  );
};

function mapStateToProps(state) {
  const { isRoutesEnabled } = state.currentUser;

  return {
    isRoutesEnabled
  };
}

export default connect(mapStateToProps)(DetailLinks);
