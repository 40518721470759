import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import omit from "lodash/omit";
import {
  Row,
  Col,
  Textarea,
  Checkbox
} from "~brokerage/components/shared/Form";
import { deepObjectKeysToSnakeCase } from "~brokerage/libs/helpers/ObjectHelper";
import { fetchPeople, fetchPeopleCounts } from "~brokerage/actions/people";
import usePrevious from "~brokerage/app/hooks/usePrevious";
import { callApi } from "~brokerage/middlewares/api";
import { buildContactDetails } from "./utils";
import UpdateContactForm from "../shared/UpdateContactForm";
import css from "./ModalUpdateContact.sass";

const ModalUpdateContact = props => {
  const {
    dispatch,
    location,
    location: {
      query: { contactId, category }
    },
    contacts
  } = props;

  const isBuyer = category === "buyer";
  const contactType = isBuyer ? "Buyer" : "Seller/Tenant";

  const selectedContact =
    contacts.find(contact => contact.id.toString() === contactId) || {};

  const initialDetails = buildContactDetails(selectedContact);

  const [archived, setArchived] = useState(initialDetails.isArchived);
  const previousArchived = usePrevious(archived);
  const history = useHistory();

  const { notes } = initialDetails;

  const handleSubmit = async modifiedValues => {
    try {
      await callApi(
        `contacts/${contactId}`,
        {},
        deepObjectKeysToSnakeCase(modifiedValues),
        "patch"
      );

      toast.success(
        `Contact has been ${archived ? "archived" : "updated"} successfully.`
      );
      closeModal();
      dispatch(fetchPeople(location.query));
      if (archived !== previousArchived) {
        dispatch(fetchPeopleCounts(location.query));
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const closeModal = () =>
    history.push({
      query: { ...omit(location.query, "modal", "contactId") }
    });

  return (
    <div className={css.base}>
      <UpdateContactForm
        title={`Edit ${contactType}`}
        initialDetails={initialDetails}
        onSubmit={handleSubmit}
        onClose={closeModal}
        additionalFields={
          <>
            <Row offset="10">
              <Textarea
                name="notes"
                defaultValue={notes}
                placeholder={`Enter notes about ${contactType.toLowerCase()} (optional).`}
                maxLength={1000}
              />
            </Row>
            <Row offset="10">
              <Col>
                <Checkbox
                  modifier="rightAligned"
                  name="isArchived"
                  checked={archived}
                  onChange={() => setArchived(!archived)}
                >
                  {`Mark the ${contactType.toLowerCase()} as archived`}
                </Checkbox>
              </Col>
            </Row>
          </>
        }
      />
    </div>
  );
};

ModalUpdateContact.propTypes = {
  location: PropTypes.object,
  dispatch: PropTypes.func,
  contacts: PropTypes.array
};

function mapStateToProps(state) {
  const { entities: contacts } = state.people.list.people;
  return {
    contacts
  };
}

export default connect(mapStateToProps)(ModalUpdateContact);
