import React from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import css from "./ModalUpdateSellerTenant.sass";
import { deepObjectKeysToSnakeCase } from "~brokerage/libs/helpers/ObjectHelper";
import { fetchSellerTenantInvolvement } from "~brokerage/actions/listings";

import { callApi } from "~brokerage/middlewares/api";
import UpdateContactForm from "../shared/UpdateContactForm";
import { useLocation } from "react-router-dom";

Modal.setAppElement(document.body);

const ModalUpdateSellerTenant = ({ isOpen, onClose, contact, dispatch }) => {
  const location = useLocation();

  const handleSubmit = async modifiedValues => {
    const listingId = location.pathname.split("/")[2];

    try {
      await callApi(
        `contacts/${contact.id}`,
        {},
        deepObjectKeysToSnakeCase(modifiedValues),
        "patch"
      );
      toast.success("Contact has been updated successfully.");
      onClose();
      dispatch(fetchSellerTenantInvolvement(listingId));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className={css.modal}
      overlayClassName={css.overlay}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <UpdateContactForm
        title="Edit Seller/Tenant"
        initialDetails={contact}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </Modal>
  );
};

export default ModalUpdateSellerTenant;
