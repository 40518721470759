export const PRICE_VALUES = [
  0, 100_000, 150_000, 200_000, 250_000, 300_000, 350_000, 400_000, 450_000,
  500_000, 550_000, 600_000, 650_000, 700_000, 750_000, 800_000, 850_000,
  900_000, 950_000, 1_000_000, 1_250_000, 1_500_000, 1_750_000, 2_000_000,
  2_500_000, 3_000_000, 5_000_000, 10_000_000, 10_000_001
];

export const DAY_IN_MILLI_SECS = 24 * 60 * 60 * 1000;

export const BED_BATH_OPTIONS = [
  { value: 0, label: "All" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3+" }
];

export const PRICE_INTERVAL_OPTIONS = [
  { value: 10000, label: "$10,000" },
  { value: 25000, label: "$25,000" },
  { value: 50000, label: "$50,000" },
  { value: 100000, label: "$100,000" }
];
