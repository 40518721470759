import React from "react";
import {
  Form,
  Row,
  Col,
  TextField,
  PhoneNumber
} from "~brokerage/components/shared/Form";
import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { getModifiedValues } from "./utils";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

const UpdateContactForm = ({
  title,
  initialDetails,
  additionalFields,
  onSubmit: updateContact,
  onClose
}) => {
  const { firstName, lastName, primaryPhone, secondaryPhone, email } =
    initialDetails;

  const handleSubmit = (_, values) => {
    const modifiedValues = getModifiedValues(values, initialDetails);

    if (isEmpty(modifiedValues)) {
      toast.info("No changes were found to update.");
      return;
    }

    updateContact(modifiedValues);
  };

  return (
    <Form onSubmit={handleSubmit} validate>
      <Header title={title} onClose={onClose} />
      <Body>
        <Row offset="15">
          <Col variant="liquid" modifier="half">
            <TextField
              name="firstName"
              defaultValue={firstName}
              block
              placeholder="First Name"
              required
              focused
            />
          </Col>
          <Col variant="liquid" modifier="half">
            <TextField
              name="lastName"
              defaultValue={lastName}
              block
              placeholder="Last Name"
              required
            />
          </Col>
        </Row>
        <Row offset="10">
          <Col variant="liquid" modifier="half">
            <PhoneNumber
              name="primaryPhone"
              defaultValue={primaryPhone}
              block
              placeholder="Primary Phone Number"
              required
            />
          </Col>
          <Col variant="liquid" modifier="half">
            <PhoneNumber
              name="secondaryPhone"
              defaultValue={secondaryPhone}
              block
              placeholder="Secondary Phone"
            />
          </Col>
        </Row>
        <Row offset="10">
          <Col variant="liquid" modifier="half">
            <TextField
              name="email"
              defaultValue={email}
              type="email"
              block
              placeholder="Email Address"
              required
            />
          </Col>
        </Row>
        {additionalFields}
      </Body>
      <Footer>
        <Button type="submit" float="right" variant="primary">
          Update
        </Button>
        <Button type="button" variant="newMessageCancel" onClick={onClose}>
          Cancel
        </Button>
      </Footer>
    </Form>
  );
};

export default UpdateContactForm;
