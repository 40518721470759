import { combineReducers } from "redux";
import {
  PRICE_VALUES,
  DAY_IN_MILLI_SECS
} from "~brokerage/constants/reports/priceAnalysis";

import {
  PRICE_ANALYSIS_REQUEST,
  PRICE_ANALYSIS_SUCCESS,
  PRICE_ANALYSIS_FAILURE,
  UPDATE_ANALYSIS_FILTERS
} from "~brokerage/constants/actionTypes";

const defaultFilters = {
  startDate: new Date(Date.now() - 30 * DAY_IN_MILLI_SECS),
  endDate: new Date(),
  beds: 0,
  baths: 0,
  zipCode: "",
  priceRange: [0, PRICE_VALUES.length - 1],
  priceInterval: 10000
};

const defaultData = {
  isFetching: false,
  data: null
};

function analysisData(state = defaultData, action) {
  switch (action.type) {
    case PRICE_ANALYSIS_REQUEST:
      return { data: null, isFetching: true };
    case PRICE_ANALYSIS_SUCCESS:
      return { data: action.data, isFetching: false };
    case PRICE_ANALYSIS_FAILURE:
      return { ...defaultData, error: action.errors };
    default:
      return state;
  }
}

function filters(state = defaultFilters, action) {
  switch (action.type) {
    case UPDATE_ANALYSIS_FILTERS:
      return { ...state, ...action.updatedFilters };
    default:
      return state;
  }
}

export default combineReducers({
  analysisData,
  filters
});
