import React, { useState } from "react";
import PropTypes from "prop-types";
import Avatar from "~brokerage/components/shared/Avatar";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import ModalUpdateSellerTenant from "~brokerage/app/components/modals/ModalUpdateSellerTenant";
import PersonPreferredCommunication from "./PersonPreferredCommunication";
import PersonCanViewFeedback from "./PersonCanViewFeedback";
import PersonShowingInvolvement from "./PersonShowingInvolvement";
import { RiPencilFill } from "@remixicon/react";
import css from "./Person.sass";
import Tooltip from "~brokerage/components/shared/Tooltip";

const Person = ({ person, onRemoveClick, onChange, size, dispatch }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {
    firstName,
    lastName,
    role,
    showingInvolvement,
    id: personId
  } = person;

  return (
    <div>
      <div>
        <div className={css.buttons}>
          <Button variant="peopleRemove" onClick={onRemoveClick}>
            <div className={css.icon}>
              <Icon name="close" />
            </div>
            Remove
          </Button>
        </div>
        <div>
          <div className={css.avatar}>
            <Avatar size={size} name={`${firstName} ${lastName}`} />
          </div>
          <div className={css.personInfo}>
            <span className={css.name}>
              {`${firstName} ${lastName}`}
              {personId && (
                <Tooltip
                  text="Edit Seller/Tenant"
                  direction="up"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <RiPencilFill
                    size={17}
                    className={css.editIcon}
                    onClick={() => setIsEditModalOpen(true)}
                  />
                </Tooltip>
              )}
            </span>
            {role && <div className={css.role}>{role}</div>}
          </div>
        </div>
      </div>
      <div>
        <PersonShowingInvolvement person={person} onChange={onChange} />
        {showingInvolvement !== "none_involvement" && (
          <>
            <PersonPreferredCommunication person={person} onChange={onChange} />
            <PersonCanViewFeedback person={person} onChange={onChange} />
          </>
        )}
      </div>
      {isEditModalOpen && (
        <ModalUpdateSellerTenant
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          dispatch={dispatch}
          contact={person}
        />
      )}
    </div>
  );
};

Person.propTypes = {
  person: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    showingInvolvement: PropTypes.string
  }),
  onRemoveClick: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.string,
  dispatch: PropTypes.func
};

export default Person;
