import moment from "moment";
import { PRICE_VALUES } from "~brokerage/constants/reports/priceAnalysis";

export const convertFiltersToApiParams = filters => {
  const {
    startDate,
    endDate,
    beds,
    baths,
    zipCode,
    priceRange,
    priceInterval
  } = filters;
  return {
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
    beds,
    baths,
    zipCode,
    priceStart: PRICE_VALUES[priceRange[0]],
    priceEnd: PRICE_VALUES[priceRange[1]],
    priceInterval
  };
};
